import { CALL_API } from '../../../middleware/api'
import * as ScrapeAllIcdApiActionConstants from "./ScrapeAllIcdApiActionConstants"
import { scrapeAllIcdUriPath } from '../EndpointResolver'

export function scrapeAllIcd() {
    return {
        [CALL_API]: {
            endpoint: scrapeAllIcdUriPath(),
            config: { method: 'get' },
            types: [
                ScrapeAllIcdApiActionConstants.SCRAPE_ALL_ICD_REQUEST,
                ScrapeAllIcdApiActionConstants.SCRAPE_ALL_ICD_SUCCESS,
                ScrapeAllIcdApiActionConstants.SCRAPE_ALL_ICD_FAILED,
            ],
        },
    }
}