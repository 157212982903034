import { CALL_API } from '../../../middleware/api'
import * as GuarantorPatientsSearchApiActionConstants from "./GuarantorPatientsSearchApiActionConstants"
import {getGuarantorPatientsSearchUriPath} from '../EndpointResolver'

export function guarantorPatientsSearch(orgId, mrn, lastName, firstName, dob, type) {
    return {
        [CALL_API]: {
            endpoint: getGuarantorPatientsSearchUriPath(orgId, mrn, "", lastName, firstName, dob, type),
            config: { method: 'get' },
            types: [
                GuarantorPatientsSearchApiActionConstants.GUARANTOR_PATIENTS_SEARCH_REQUEST,
                GuarantorPatientsSearchApiActionConstants.GUARANTOR_PATIENTS_SEARCH_SUCCESS,
                GuarantorPatientsSearchApiActionConstants.GUARANTOR_PATIENTS_SEARCH_FAILED,
            ],
        },
    }
}


export function guarantorPatientsSearchClear() {
     return function (dispatch) {
         dispatch({
             type: GuarantorPatientsSearchApiActionConstants.GUARANTOR_PATIENTS_SEARCH_CLEAR
         })
     }
}