import React, { useState, Component } from "react";
import "../../App.css";
import "./Documentation.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Table, Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeftPanel from "../common/leftPanel/LeftPanel";
import RightPanel from "../common/leftPanel/RightPanel";
import LeftPanelMobile from "../common/leftPanel/LeftPanelMobile";
import Search from "../common/search/Search";
import { addUpdateDocumentation } from "../../redux/actions/api/documentation/AddUpdateDocumentationApiActions";
import { getPatientDocumentation } from "../../redux/actions/api/documentation/GetPatientDocumentationApiActions";
import { getDocumentationUploadFileUriPath } from "../../redux/actions/api/EndpointResolver";
import {
  guarantorPatientsSearchClear,
  guarantorPatientsSearch,
} from "../../redux/actions/api/guarantor-patients-search/GuarantorPatientsSearchApiActions";
import { addUpdatePatientSelected } from "../../redux/actions/api/patient/AddUpdatePatientApiActions";
import axios from "axios";
import moment from "moment";
import {
  getDocumentationDownloadFile,
  documentationDownloadFileClear,
} from "../../redux/actions/api/documentation/DocumentationDownloadFileApiActions";

import { Routes, Route } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";
import { formatDateToCustomISO } from "../common/UtilsDate";

class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPatientsModal: false,
      searchMrn: null,
      searchLastName: null,
      searchFirstName: null,
      searchDob: null,

      key: 1,
      id: "",
      clientId: "",

      patientId: "",
      authorizationId: "",
      orgId: "",
      documentName: "",
      documentType: "",
      dateUploaded: "",
      username: "",
      submitted: "",
      filename: "",
      status: "",

      selectedFile: null,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  documentationDefault = {
    id: "",
    clientId: "",

    patientId: "",
    authorizationId: "",
    orgId: "",
    documentName: "",
    documentType: "",
    dateUploaded: "",
    username: "",
    submitted: "",
    filename: "",
    status: "",
  };

  handleSelect(key) {
    console.log("selected" + key);
    this.setState({ key: key });
  }

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  patientNameFocus = () => {
    this.setState({ searchPatientsModal: true });
  };

  searchPatientsModalClose = () => {
    this.setState({ searchPatientsModal: false });
  };

  showSearchPatientDialog = () => {
    this.setState({ searchPatientsModal: true });
  };

  searchPatients = () => {
    const { searchMrn, searchLastName, searchFirstName, searchDob } =
      this.state;
    const {user} = this.props;
    let orgId = null;
    if(user.userRole != 'AUTHOFI ADMIN') {
        orgId = user.orgId;
    }
    let dob = undefined;
    if (!searchMrn && !searchLastName && !searchFirstName && !searchDob) return;
    let lastName = searchLastName
      ? searchLastName.toString().toUpperCase()
      : null;
    let firstName = searchFirstName
      ? searchFirstName.toString().toUpperCase()
      : null;
    try {
      if (searchDob) {
        dob = moment(searchDob).format("MM/DD/YYYY");
      } else {
        dob = undefined;
      }
      this.props.guarantorPatientsSearch(
        orgId,
        searchMrn,
        lastName,
        firstName,
        dob,
        "P"
      );
    } catch (err) {
      console.log(err);
    }
  };

  saveDocumentation = async () => {
    var { isNew, filename, documentType, documentName, selectedFile } =
      this.state;
    const { addedPatient } = this.props;

    console.log(selectedFile);

    if (selectedFile != null && selectedFile != "") {
      var name = selectedFile.name;
      var fileType = name.substring(name.length - 3);
      filename =
        addedPatient.clientId +
        "_" +
        addedPatient.id +
        "_" +
        documentType +
        "_" +
        documentName +
        "." +
        fileType;
      filename = filename.replace(" ", "_");
      var response = await this.uploadFile(
        selectedFile,
        filename,
        addedPatient.clientId
      );
      if (response.success) {
      } else {
        return;
      }
    }

    var c = document.getElementsByClassName("myfile");
    for (var b = 0; b < c.length; b++) {
      c[b].value = null;
    }

    var dateUploaded = "";
    dateUploaded = new Date();
    console.log(dateUploaded);

    var documentation = {
      ...this.state,
      patientId: addedPatient.id,
      clientId: addedPatient.clientId,
      orgId: addedPatient.orgId,
      filename: filename,
      username: "wgumaru",
      dateUploaded: dateUploaded,
    };

    await this.props.addUpdateDocumentation(documentation);
    await this.props.getPatientDocumentation(addedPatient.id);
    if (isNew == "Y") {
      this.setState(this.documentationDefault);
    }
  };

  uploadFile = async (file, fileName, clientName) => {
    //var axios = require('axios');
    var FormData = require("form-data");
    //var fs = require('fs');
    var data = new FormData();
    data.append("file", file);

    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

    var config = {
      method: "post",
      url:
        getDocumentationUploadFileUriPath() +
        "?name=" +
        fileName +
        "&clientName=" +
        clientName,
      headers: {
        Authorization: localStorage.getItem("id_token") || null,
        //...data.getHeaders()
      },
      data: data,
    };
    var retData = {};
    retData.success = false;
    await axios(config)
      .then(function (response) {
        retData.success = true;
        retData.response = response.data;
      })
      .catch(function (error) {
        retData.success = false;
        retData.error = error;
      });
    return retData;
  };

  viewAttachment = async (record) => {
    var fileName = record.filename;
    var filetype = fileName.substring(fileName.length - 3);
    var type = "image/tif";
    if (filetype.toUpperCase() == "PNG") {
      localStorage.setItem("tickler_sent_attachment_filetype", "image/png");
      type = "image/png";
    } else if (filetype.toUpperCase() == "PDF") {
      localStorage.setItem(
        "tickler_sent_attachment_filetype",
        "application/pdf"
      );
      type = "application/pdf";
    } else if (filetype.toUpperCase() == "TIF") {
      localStorage.setItem("tickler_sent_attachment_filetype", "image/tif");
      type = "image/tif";
    } else if (filetype.toUpperCase() == "JPG") {
      localStorage.setItem("tickler_sent_attachment_filetype", "image/jpg");
      type = "image/jpg";
    } else {
      localStorage.setItem(
        "tickler_sent_attachment_filetype",
        "application/octet-stream"
      );
      type = "application/octet-stream";

      // download etc files...
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //window.navigator.msOpenBlob(file, "Statement.pdf");
        this.props.getDocumentationDownloadFile(fileName, record.clientId);
        console.log("I am here 1");
      } else {
        await this.props.getDocumentationDownloadFile(
          fileName,
          record.clientId
        );

        const file = new Blob([this.props.documentaitonDownloadFile], {
          type: type,
        });

        const fileURL = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = fileName;
        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(fileURL);
            a.removeEventListener("click", clickHandler);
          }, 150);
        };
        a.addEventListener("click", clickHandler, false);
        a.click();
        console.log("I am here 2");
        return;
      }
    }
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //window.navigator.msOpenBlob(file, "Statement.pdf");
      this.props.getDocumentationDownloadFile(fileName, "");
      console.log("I am here 3");
    } else {
      //localStorage.setItem("tickler_sent_attachment_filename", data.attachmentFilename);
      //localStorage.setItem("tickler_sent_parent_id", data.parentId);
      //window.open('../attachment');
      await this.props.getDocumentationDownloadFile(fileName, record.clientId);
      console.log("I am here 5 c", record.clientId);
      if (this.props.documentationDownloadFile instanceof Blob) {
        const file = new Blob([this.props.documentationDownloadFile], {
          type: type,
        });

        const fileURL = URL.createObjectURL(file);

        this.props.documentationDownloadFileClear();
        console.log("I am here 5");
        window.open(fileURL, "_blank");
      }
    }
  };

  componentDidMount() {
    const { addedPatient } = this.props;
    if (addedPatient.id) {
      this.props.getPatientDocumentation(addedPatient.id);
    }
    this.props.guarantorPatientsSearchClear();
  }

  onClickHandler(record, e) {
    this.setState(record);

    const elementsShow = document.querySelectorAll("tr.show");
    const hiddenElement = e.currentTarget.nextSibling;

    /*Reset all show details*/
    elementsShow.forEach((element) => {
      if (hiddenElement != element) {
        element.classList.remove("show");
      }
    });

    /*Set selected row*/
    const selected = e.currentTarget;
    const elementsSelected = document.querySelectorAll("tr.selected-table-row");

    elementsSelected.forEach((element) => {
      if (selected != element) {
        element.classList.remove("selected-table-row");
      }
    });

    selected.classList.add("selected-table-row");

    /*Reset all up arrow*/
    const elementsArrow = document.querySelectorAll(".fa-chevron-up");
    elementsArrow.forEach((element) => {
      element.classList.remove("fa-chevron-up");
      element.classList.add("fa-chevron-down");
    });

    /*set up and show details on selected row*/
    const arrowSelected = document.querySelectorAll(".selected-table-row .fa");

    if (hiddenElement.className.indexOf("collapse show") > -1) {
      hiddenElement.classList.remove("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-up");
        element.classList.add("fa-chevron-down");
      });
    } else {
      hiddenElement.classList.add("show");

      arrowSelected.forEach((element) => {
        element.classList.remove("fa-chevron-down");
        element.classList.add("fa-chevron-up");
      });
    }
    //}

    //this.setState({btnClick:'N'});
  }

  render() {
    const { searchPatientsModal } = this.state;
    var { documentationList, addedPatient } = this.props;
    if (!documentationList) {
      documentationList = [];
    }

    var MaskDateInput = [
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      "/",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];

    var documentList;
    if (addedPatient.id) {
      documentList = (
        <Table bordered className="table-main mt-2">
          <thead>
            <tr>
              <th className="td-first"></th>
              <th>Document Type</th>
              <th>Document Name</th>
              <th>Date Uploaded</th>
              <th>User</th>
              <th className="td-last">File Name</th>
            </tr>
          </thead>
          <tbody>
            {documentationList.map((record, key) => {
              return (
                <>
                  <tr
                    onClick={(event) =>
                      this.onClickHandler({ ...record, isNew: "N" }, event)
                    }
                  >
                    <td className="td-first">
                      <i className="fa fa-chevron-down"></i>
                    </td>
                    <td>{record.documentType}</td>
                    <td>{record.documentName}</td>
                    <td>{moment(record.dateUploaded).format("MM/DD/YYYY")}</td>
                    <td>{record.username}</td>
                    <td className="td-last">{record.filename}</td>
                  </tr>
                  <tr className="main-table collapse">
                    <td className="td-first" colSpan="3">
                      <Form>
                        <Row className="mb-1">
                          <Form.Group as={Col} controlId="">
                            <Form.Label className="table-form-label mb-0">
                              Type
                            </Form.Label>
                            <Form.Select
                              className="grid-form-control-select"
                              value={this.state.documentType}
                              onChange={(e) =>
                                this.setState({ documentType: e.target.value })
                              }
                            >
                              <option value="">Select Type</option>
                              <option value="MEDICAL RECORD">
                                MEDICAL RECORD
                              </option>
                              <option value="INSURANCE CARD">
                                INSURANCE CARD
                              </option>
                              <option value="DRIVERS LICENSE">
                                DRIVERS LICENSE
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Row>
                        <Row className="mb-1">
                          <div class="mt-2">
                            <Form.Group as={Col} controlId="">
                              <Form.Label className="table-form-label mb-0">
                                Document Name
                              </Form.Label>
                              <Form.Control
                                className="grid-form-control"
                                value={this.state.documentName}
                                onChange={(e) =>
                                  this.setState({
                                    documentName: e.target.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </div>
                        </Row>
                        <Form.Group
                          as={Col}
                          controlId=""
                          className="table-form-label mb-0 mt-2"
                        >
                          <form id="attachform" name="attachform">
                            <Form.Control
                              type="file"
                              id="myfile"
                              accept="application/pdf"
                              text="Edit File"
                              name="myfile"
                              className="myfile fileEdit"
                              onChange={(e) =>
                                this.setState({
                                  selectedFile: e.target.files[0],
                                })
                              }
                            />
                          </form>
                        </Form.Group>
                        <Row>
                          <div className="d-flex">
                            <Button
                              className="form-save-btn btn-primary mt-1"
                              type="button"
                              onClick={() => {
                                this.saveDocumentation();
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              className="form-save-btn btn-primary mt-1"
                              type="button"
                              onClick={() => {
                                this.viewAttachment(record);
                              }}
                            >
                              View Attachment
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </td>
                    <td className="td-last" colSpan="3"></td>
                  </tr>
                </>
              );
            })}
            <tr
              onClick={(event) =>
                this.onClickHandler(
                  { ...this.documentationDefault, isNew: "Y" },
                  event
                )
              }
            >
              <td className="td-first">
                <i className="fa fa-chevron-down"></i>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="td-last"></td>
            </tr>
            <tr className="main-table collapse">
              <td className="td-first" colSpan="3">
                <Form>
                  <Row className="mb-1">
                    <div class="mt-2">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Document Type
                        </Form.Label>
                        <Form.Select
                          className="grid-form-control-select"
                          value={this.state.documentType}
                          onChange={(e) =>
                            this.setState({ documentType: e.target.value })
                          }
                        >
                          <option value="">Select Type</option>
                          <option value="MEDICAL RECORD">MEDICAL RECORD</option>
                          <option value="INSURANCE CARD">INSURANCE CARD</option>
                          <option value="DRIVERS LICENSE">
                            DRIVERS LICENSE
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Row>
                  <Row className="mb-1">
                    <div class="mt-2">
                      <Form.Group as={Col} controlId="">
                        <Form.Label className="table-form-label mb-0">
                          Document Name
                        </Form.Label>
                        <Form.Control
                          className="grid-form-control"
                          value={this.state.documentName}
                          onChange={(e) =>
                            this.setState({ documentName: e.target.value })
                          }
                        />
                      </Form.Group>
                    </div>
                  </Row>
                  <Row className="mb-1">
                    <div class="mt-2">
                      <Form.Group
                        as={Col}
                        controlId=""
                        className="table-form-label mb-0 mt-2"
                      >
                        <form id="attachform" name="attachform">
                          <Form.Control
                            type="file"
                            id="myfile"
                            name="myfile"
                            accept="application/pdf"
                            className="myfile"
                            onChange={(e) =>
                              this.setState({ selectedFile: e.target.files[0] })
                            }
                          />
                        </form>
                      </Form.Group>
                    </div>
                  </Row>
                  <Row>
                    <div>
                      <Button
                        className="form-save-btn btn-primary mt-1"
                        type="button"
                        onClick={() => {
                          this.saveDocumentation();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Row>
                </Form>
              </td>
              <td className="td-last" colSpan="3" />
            </tr>
          </tbody>
        </Table>
      );
    }

    return (
      <div className="w-100">
        <div className="auth-content-header d-flex justify-content-between">
          <div className="d-flex">
            <h5>
              <img alt="#" src={require("../../images/AuthoFiLogo.jpg")} />
            </h5>
          </div>
          <div className="d-flex justify-content-end">
            <Search />
            <RightPanel />
          </div>
        </div>
        <Modal show={searchPatientsModal} size="lg" centered>
          <Modal.Header>
            <label>Search Patient</label>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    MRN:
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="mrn"
                    type="text"
                    placeholder="Enter MRN"
                    onChange={(e) =>
                      this.setState({ searchMrn: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    Last Name:{" "}
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="lastname"
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={(e) =>
                      this.setState({ searchLastName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    First Name:
                  </Form.Label>
                  <Form.Control
                    className="grid-form-control"
                    name="firstname"
                    type="text"
                    placeholder="Enter First Name"
                    onChange={(e) =>
                      this.setState({ searchFirstName: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="search-form-group">
                  <Form.Label className="mb-0 text-muted table-form-label">
                    Date of Birth:
                  </Form.Label>
                  <ReactDatePicker
                    className="date-picker"
                    calendarIconClassname="date-picker-icon"
                    placeholderText="MM/DD/YYYY"
                    showIcon
                    onChange={(date) =>
                      this.setState({
                        searchDob: formatDateToCustomISO(date),
                      })
                    }
                    selected={
                      this.state.searchDob
                        ? formatDateToCustomISO(
                            moment(this.state.searchDob).toDate()
                          )
                        : null
                    }
                    customInput={
                      <MaskedTextInput type="text" mask={MaskDateInput} />
                    }
                  />
                </Form.Group>
              </Col>
              <Col className="pt-2">
                <Button
                  className="btn grid-btn"
                  variant="primary"
                  onClick={this.searchPatients.bind(this)}
                >
                  Search
                </Button>
              </Col>
            </Row>
            <div className="mt-3">
              <label>Search Result(s)</label>
            </div>
            <Table bordered className="table-main">
              <thead>
                <tr>
                  <th className="td-first">MRN</th>
                  <th>Last Name</th>
                  <th>First Name</th>
                  <th className="td-last">Date of Birth</th>
                </tr>
              </thead>
              <tbody>
                {this.props.guarantorPatientsSearchResults.map(
                  (patient, key) => {
                    return (
                      <>
                        <tr
                          key={key}
                          onClick={() => {
                            this.props.addUpdatePatientSelected(patient);
                            this.props.getPatientDocumentation(patient.id);
                            this.searchPatientsModalClose();
                          }}
                        >
                          <td className="td-first">{patient.mrn}</td>
                          <td>{patient.lastName}</td>
                          <td>{patient.firstName}</td>
                          <td className="td-last">{patient.dob}</td>
                        </tr>
                      </>
                    );
                  }
                )}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn grid-btn"
              variant="secondary"
              onClick={this.searchPatientsModalClose.bind(this)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="auth-content-main d-flex align-items-center justify-content-center">
          <div className="auth-content-content p-2">
            <div class="form-title-label mt-2">
              <label>
                Patient:
                <a
                  className="selectPatient"
                  onClick={() => {
                    this.showSearchPatientDialog();
                  }}
                >
                  {addedPatient.id
                    ? addedPatient.lastName + ", " + addedPatient.firstName
                    : "Select Patient"}
                </a>
              </label>
            </div>

            <div class="form-title-label mt-3">
              <label class="border-bottom" class="border-bottom">
                Document(s)
              </label>
            </div>
            {documentList}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUpdateDocumentation: bindActionCreators(
      addUpdateDocumentation,
      dispatch
    ),
    getPatientDocumentation: bindActionCreators(
      getPatientDocumentation,
      dispatch
    ),
    guarantorPatientsSearch: bindActionCreators(
      guarantorPatientsSearch,
      dispatch
    ),
    addUpdatePatientSelected: bindActionCreators(
      addUpdatePatientSelected,
      dispatch
    ),
    getDocumentationDownloadFile: bindActionCreators(
      getDocumentationDownloadFile,
      dispatch
    ),
    documentationDownloadFileClear: bindActionCreators(
      documentationDownloadFileClear,
      dispatch
    ),
    guarantorPatientsSearchClear: bindActionCreators(
      guarantorPatientsSearchClear,
      dispatch
    ),
  };
};

const mapStateToProps = (state) => {
  return {
    addedPatient: state.addUpdatePatient.data,
    documentationList: state.getPatientDocumentation.data,
    guarantorPatientsSearchResults:
      state.guarantorPatientsSearch.guarantorPatientsSearchResults,
    documentationDownloadFile: state.documentationDownloadFile.data,
    user: state.currentUser.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documentation);
