import React, { Component } from "react";
import LeftPanel from '../leftPanel/LeftPanel';
import {AuthenticatedAuthofiRoutes} from "../../../routes";
import "../../../App.css"

class MainViewAuthofi extends Component {

    render() {
        return(
            <div className="auth-main-container d-flex">
                <div className="left-panel">
                    <LeftPanel/>
                </div>
                <div className="auth-content-container">
                    <AuthenticatedAuthofiRoutes />
                </div>
            </div>
        );
    }
}

export default MainViewAuthofi;