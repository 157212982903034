import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import {connect} from 'react-redux'
import { bindActionCreators } from "redux";
import {
    PublicRoutes,
    AuthenticatedRoutes
} from './routes'

import getUser from "./redux/actions/api/authentication/GetUserApiActions";

import './App.css';
import MainView from "./components/common/main/MainView";
import MainViewAuthofi from "./components/common/main/MainViewAuthofi";

const App = (props) => {

    const renderPublicPage = () => (<PublicRoutes />);
    const renderAuthenticatedPage = () => (<MainView />);
    const renderAuthenticatedPageAuthofi = () => (<MainViewAuthofi />);
    if(props.isAuthenticated){
        let userId = sessionStorage.getItem("userId");
        props.getUser(userId);

        if(props.user.userRole == "AUTHOFI ADMIN"){

            return renderAuthenticatedPageAuthofi();
        }else{
            return renderAuthenticatedPage();
        }


    }

    return renderPublicPage();
}

function mapStateToProps(state) {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        user: state.currentUser.data,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getUser: bindActionCreators(getUser, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App)
