import React, { useState , useRef} from "react";
import { Table, Tab, Tabs, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Search.css";
import Col from "react-bootstrap/Col";

import {
  selectedMenu,
  selectedMenuSearch,
} from "../../../redux/actions/api/menu/MenuApiActions";

import { guarantorPatientsSearch } from "../../../redux/actions/api/guarantor-patients-search/GuarantorPatientsSearchApiActions";
import { guarantorSearch } from "../../../redux/actions/api/guarantor-patients-search/GuarantorSearchApiActions";
import { patientsSearch } from "../../../redux/actions/api/guarantor-patients-search/PatientsSearchApiActions";

import getEmployeeByOrgId from "../../../redux/actions/api/employee/GetEmployeeByOrgIdApiActions";
import getFacilities from "../../../redux/actions/api/facility/GetFacilitiesApiActions";
import getEmployeeByClientId from "../../../redux/actions/api/employee/GetEmployeeByClientIdApiActions";
import getEmployeeByFacilityId from "../../../redux/actions/api/employee/GetEmployeeByFacilityIdApiActions";

import { WithRouter } from "../../common/WithRouter";

import moment from "moment";

import DatePicker from "react-datepicker";
import MaskedInput from "react-maskedinput";

import DashboardFilterModal from "./DashboardFilterModal";

import getClientsByOrgId from "../../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import getPayersByOrgId from "../../../redux/actions/api/payer/GetPayersByOrgIdApiActions";
import { patientPhoneEmailClear } from "../../../redux/actions/api/phoneEmail/GetPatientPhoneEmailApiActions";
import { patientAddressClear } from "../../../redux/actions/api/address/GetPatientAddressApiActions";
import { providerClear } from "../../../redux/actions/api/provider/GetProviderApiActions";
import { clientEmployeesClear } from "../../../redux/actions/api/employee/GetClientEmployeesApiActions";
import facilitiesClear from "../../../redux/actions/api/facility/GetFacilitiesApiActions";

import { addUpdateClientSelected } from "../../../redux/actions/api/client/AddUpdateClientApiActions";
import MaskedTextInput from "react-text-mask";
import { correspondenceSearch } from "./../../../redux/actions/api/correspondence/CorrespondenceSearchApiActions";

import IdleTimer from 'react-idle-timer';

import {logout} from '../../../redux/actions/api/authentication/AuthenticationApiActions';

const defaultCorrSearchObject = {
  title: "",
  clientId: "",
  patientId: "",
  authorizationId: "",
  payerId: "",
};

const Search = (props) => {
  let [searchMrn, setMrn] = useState("");
  let [searchAccountNumber, setAccountNumber] = useState("");
  let [searchLastName, setLastName] = useState("");
  let [searchFirstName, setFirstName] = useState("");
  let [searchDob, setDob] = useState(null);
  let [show, setShow] = useState(false);
  let [showFilter, setShowFilter] = useState(false);
  let [filterName, setFilterName] = useState("");
  let [showIdleTimerDialog, setShowIdleTimerDialog] = useState(false);
  let [corrSearchObject, setCorrSearchObject] = useState(
    defaultCorrSearchObject
  );

  const idleTimer = useRef(null);

  let onIdle = (e) => {
      setShowIdleTimerDialog(true);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let search = () => {
    var { menu, user } = props;
    var accountNumberSearch;

    let orgId = null;
    if(user.userRole != 'AUTHOFI ADMIN') {
        orgId = user.orgId;
    }

    console.log({ menu });

    if (!menu) {
      props.selectedMenu("Patients");
      menu = "Patients";
    }

    var recordType = "P";
    if (menu == "Guarantors") {
      recordType = "G";
    }

    //const { searchMrn, searchLastName, searchFirstName, searchDob } = this.state;
    let dob = undefined;
    if (
      !searchMrn &&
      !searchAccountNumber &&
      !searchLastName &&
      !searchFirstName &&
      !searchDob
    )
      return;
    let lastName = searchLastName
      ? searchLastName.toString().toUpperCase()
      : null;
    let firstName = searchFirstName
      ? searchFirstName.toString().toUpperCase()
      : null;
    try {
      console.log(searchDob);
      if (searchDob) {
        dob = moment(searchDob).format("YYYY-MM-DD");
      } else {
        dob = undefined;
      }
      if (recordType == "G") {
        props.guarantorSearch(orgId, searchMrn, lastName, firstName, dob, recordType);
        props.navigate("/guarantor");
      } else {
        props.patientsSearch(
          orgId,
          searchMrn,
          searchAccountNumber,
          lastName,
          firstName,
          dob,
          recordType
        );
        props.navigate("/patients");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSearchCorrespondence = async () => {
    const { title, clientId, patientId, authorizationId, payerId } =
      corrSearchObject;

    if (Object.values(corrSearchObject).every((val) => !val)) {
      return;
    }

    try {
      await props.getCorrespondenceListBySearch(
        title,
        clientId,
        patientId,
        authorizationId,
        payerId
      );
    } catch (err) {
      console.log(err);
    }

    setCorrSearchObject(defaultCorrSearchObject);
  };

  let handleClientsOrgId = (e) => {
    const { selectedMenuSearch, menuSearch } = props;

    var orgId = e.target.value;
    console.log("handleClientsOrgId" + orgId);
    menuSearch.clientOrgId = orgId;

    selectedMenuSearch(menuSearch);

    if (orgId != undefined) {
      props.getClientsByOrgId(orgId);
    }

    clearClientAndList();
    console.log("this.state.orgId", orgId);

    props.navigate("/refreshclients");
  };

  let handlePayersOrgId = (e) => {
    const { selectedMenuSearch, menuSearch } = props;

    var orgId = e.target.value;
    console.log("handleClientsOrgId" + orgId);
    menuSearch.payerOrgId = orgId;

    selectedMenuSearch(menuSearch);

    if (orgId != undefined) {
      props.getClientsByOrgId(orgId);
      props.getPayersByOrgId(orgId);
      console.log("I am here handlePayersOrgId");
    }

    props.navigate("/payer");
  };


  let handleEmpOrg = (e) => {
    //const state = this.state;

    const { selectedMenuSearch, menuSearch } = props;

    var orgId = e.target.value;
    console.log("handleClientsOrgId" + orgId);
    menuSearch.empOrgId = orgId;

    selectedMenuSearch(menuSearch);

    //this.setState({orgId:orgId});

    props.getClientsByOrgId(orgId);
    props.getEmployeeByOrgId(orgId);
  };

  let handleEmpClient = (e) => {
    //const state = this.state;

    const { selectedMenuSearch, menuSearch } = props;

    var clientId = e.target.value;

    menuSearch.empClientId = clientId;

    selectedMenuSearch(menuSearch);

    //this.setState({clientId:clientId});

    props.getFacilities(clientId);
    props.getEmployeeByClientId(clientId);
  };

  let handleEmpFacility = (e) => {
    //const state = this.state;

    const { selectedMenuSearch, menuSearch } = props;

    var facilityId = e.target.value;

    menuSearch.empFacilityId = facilityId;

    selectedMenuSearch(menuSearch);

    props.getEmployeeByFacilityId(facilityId);
  };

  let clearClientAndList = async () => {
    props.addUpdateClientSelected({});
    props.patientPhoneEmailClear();
    props.patientAddressClear();
    props.facilitiesClear();
    props.providerClear();
    props.clientEmployeesClear();
  };

  let filterPayers = (e) => {
    const { selectedMenuSearch, menuSearch } = props;
    menuSearch.payerFilterName = filterName;
    selectedMenuSearch(menuSearch);
    props.navigate("/payer");
  };

  var { menu, organizationList, clientList, facilityList, menuSearch, user } = props;

  if (!organizationList) {
    organizationList = [];
  }

  if (!clientList) {
    clientList = [];
  }

  if (!facilityList) {
    facilityList = [];
  }

  var portalFilterBtn;

  var accountNumberSearch;
  var mrnSearch;
  var lastNameSearch;
  var firstNameSearch;
  var dobSearch;
  var acctSearchBtn;
  var clientOrgSearch;
  var payerOrgSearch;
  var payerSearch;
  var payerSearchBtn;
  var empOrgSearch;
  var empClientSearch;
  var empFacilitySearch;
  var corrTitleSearch;
  var corrClientSearch;
  var corrPatientSearch;
  var corrAuthIdSearch;
  var corrPayerSearch;
  var corrSearchBtn;

  var MaskDateInput = [
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  if (!menu) {
    props.selectedMenu("Portal");
    menu = "Portal";
  }

  console.log("menu:", menu);

  if (menu == "Portal") {
    portalFilterBtn = (
      <Form.Group className="search-form-group">
        <Button
          className="btn btn-primary header-filter-btn"
          variant="primary"
          type="button"
          onClick={(e) => setShowFilter(true)}
        >
          <img
            src={require("../../../images/header-filter.png")}
            className="header-filter"
          />
        </Button>
      </Form.Group>
    );
  } else if (menu == "Patients" || menu == "Guarantors") {
    if (menu == "Patients") {
      accountNumberSearch = (
        <Form.Group className="search-form-group">
          <Form.Label className="mb-0 text-muted form-label">
            Account Number:{" "}
          </Form.Label>
          <Form.Control
            name="accountnumber"
            type="text"
            placeholder="Enter Account Number"
            onChange={(e) => setAccountNumber(e.target.value)}
          />
        </Form.Group>
      );
    }

    mrnSearch = (
      <Form.Group className="search-form-group">
        <Form.Label className="mb-0 text-muted form-label">MRN:</Form.Label>
        <Form.Control
          name="mrn"
          type="text"
          placeholder="Enter MRN"
          onChange={(e) => setMrn(e.target.value)}
        />
      </Form.Group>
    );
    lastNameSearch = (
      <Form.Group className="search-form-group">
        <Form.Label className="mb-0 text-muted form-label">
          Last Name:{" "}
        </Form.Label>
        <Form.Control
          name="lastname"
          type="text"
          placeholder="Enter Last Name"
          onChange={(e) => setLastName(e.target.value)}
        />
      </Form.Group>
    );
    firstNameSearch = (
      <Form.Group className="search-form-group">
        <Form.Label className="mb-0 text-muted form-label">
          First Name:
        </Form.Label>
        <Form.Control
          name="firstname"
          type="text"
          placeholder="Enter First Name"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Form.Group>
    );
    dobSearch = (
      <Form.Group className="search-form-group d-flex flex-column">
        <Form.Label className="mb-0 text-muted form-label search-dob">
          Date of Birth:
        </Form.Label>
        <DatePicker
          className="search-date-picker"
          showIcon
          calendarIconClassname="search-date-picker-icon"
          placeholderText="MM/DD/YYYY"
          customInput={<MaskedTextInput type="text" mask={MaskDateInput} />}
          selected={searchDob}
          onChange={(date) => setDob(date)}
        />
      </Form.Group>
    );
    acctSearchBtn = (
      <Form.Group className="search-form-group">
        <Button
          className="btn btn-primary header-search-btn"
          variant="primary"
          type="button"
          onClick={(e) => search()}
        >
          <img
            src={require("../../../images/header-search.png")}
            className="header-search"
          />
        </Button>
      </Form.Group>
    );
  } else if (menu == "Clients") {
    if(user.userRole == 'AUTHOFI ADMIN') {
        clientOrgSearch = (
          <Form.Group className="search-form-group d-flex flex-column">
            <Form.Label className="mb-0 text-muted form-label search-dob">
              Organization:
            </Form.Label>
            <Form.Select
              onChange={(e) => handleClientsOrgId(e)}
              value={menuSearch.clientOrgId}
            >
              <option readOnly selected value>
                {" "}
                -- Select Organization --{" "}
              </option>
              {organizationList.map((record, key) => {
                return (
                  <option value={record.id} key={key}>
                    {record.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        );
    }
  } else if (menu == "Payers") {
    if(user.userRole == 'AUTHOFI ADMIN') {
    payerOrgSearch = (
        <Form.Group className="search-form-group d-flex flex-column">
          <Form.Label className="mb-0 text-muted form-label search-dob">
            Organization:
          </Form.Label>
          <Form.Select
            onChange={(e) => handlePayersOrgId(e)}
            value={menuSearch.payerOrgId}
          >
            <option readOnly selected value>
              {" "}
              -- Select Organization --{" "}
            </option>
            {organizationList.map((record, key) => {
              return (
                <option value={record.id} key={key}>
                  {record.name}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
      );
    }
    payerSearch = (
      <Form.Group className="search-form-group d-flex flex-column">
        <Form.Label className="text-muted form-label">Payer Name: </Form.Label>
        <Form.Control
          maxLength={40}
          required
          placeholder=""
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />
      </Form.Group>
    );

    payerSearchBtn = (
      <Form.Group className="search-form-group">
        <Button
          className="btn btn-primary header-search-btn"
          variant="primary"
          onClick={(e) => filterPayers(e)}
        >
          Filter
        </Button>
      </Form.Group>
    );
  } else if (menu == "Employees") {
    if(user.userRole == 'AUTHOFI ADMIN') {
        empOrgSearch = (
          <Form.Group className="search-form-group d-flex flex-column">
            <Form.Label className="mb-0 text-muted form-label search-dob">
              Organization:
            </Form.Label>
            <Form.Select
              onChange={(e) => handleEmpOrg(e)}
              value={menuSearch.empOrgId}
            >
              <option readOnly> -- Select Organization -- </option>
              {organizationList.map((record, key) => {
                return (
                  <option value={record.id} key={key}>
                    {record.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        );
    }
    empClientSearch = (
      <Form.Group className="search-form-group d-flex flex-column">
        <Form.Label className="mb-0 text-muted form-label search-dob">
          Client:
        </Form.Label>
        <Form.Select
          onChange={(e) => handleEmpClient(e)}
          value={menuSearch.empClientId}
        >
          <option readOnly selected value>
            {" "}
            -- Select Client --{" "}
          </option>
          {clientList.map((record, key) => {
            return (
              <option value={record.id} key={key}>
                {record.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    );
    empFacilitySearch = (
      <Form.Group className="search-form-group d-flex flex-column">
        <Form.Label className="mb-0 text-muted form-label search-dob">
          Facility:
        </Form.Label>
        <Form.Select
          onChange={(e) => handleEmpFacility(e)}
          value={menuSearch.empFacilityId}
        >
          <option readOnly selected value>
            {" "}
            -- Select Facility --{" "}
          </option>
          {facilityList.map((record, key) => {
            return (
              <option value={record.id} key={key}>
                {record.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    );
  } else if (menu === "Correspondence") {
    corrTitleSearch = (
      <Form.Group className="search-form-group">
        <Form.Label className="form-label">Title:</Form.Label>
        <Form.Control
          name="title"
          type="text"
          placeholder="Enter Title"
          value={corrSearchObject.title}
          onChange={(e) =>
            setCorrSearchObject({
              ...corrSearchObject,
              title: e.target.value,
            })
          }
        />
      </Form.Group>
    );
    corrClientSearch = (
      <Form.Group className="search-form-group d-flex flex-column">
        <Form.Label className="form-label">Client:</Form.Label>
        <Form.Select
          className="form-control"
          value={corrSearchObject.clientId}
          onChange={(e) =>
            setCorrSearchObject({
              ...corrSearchObject,
              clientId: e.target.value,
            })
          }
        >
          <option readOnly selected value>
            -- Select Client --
          </option>
          {props.clientList.map((client, key) => {
            return (
              <option key={key} value={client.id}>
                {client.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    );
    corrPatientSearch = (
      <Form.Group className="search-form-group d-flex flex-column">
        <Form.Label className="form-label">Patient Id:</Form.Label>
        <Form.Control
          name="patientId"
          type="text"
          placeholder="Enter Authorization ID"
          value={corrSearchObject.patientId}
          onChange={(e) =>
            setCorrSearchObject({
              ...corrSearchObject,
              patientId: e.target.value,
            })
          }
        />
      </Form.Group>
    );
    corrAuthIdSearch = (
      <Form.Group className="search-form-group">
        <Form.Label className="form-label">Authorization ID:</Form.Label>
        <Form.Control
          name="authorizationId"
          type="text"
          placeholder="Enter Authorization ID"
          value={corrSearchObject.authorizationId}
          onChange={(e) =>
            setCorrSearchObject({
              ...corrSearchObject,
              authorizationId: e.target.value,
            })
          }
        />
      </Form.Group>
    );
    corrPayerSearch = (
      <Form.Group className="search-form-group d-flex flex-column">
        <Form.Label className="form-label">Payer Name: </Form.Label>
        <Form.Select
          className="form-control"
          value={corrSearchObject.payerId}
          onChange={(e) =>
            setCorrSearchObject({
              ...corrSearchObject,
              payerId: e.target.value,
            })
          }
        >
          <option value="">Select patient</option>
          {props.payers.map((payer, key) => {
            return (
              <option key={key} value={payer.id}>
                {payer.name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    );
    corrSearchBtn = (
      <Form.Group className="search-form-group">
        <Button
          className="btn btn-primary header-search-btn"
          variant="primary"
          type="button"
          onClick={handleSearchCorrespondence}
        >
          <img
            src={require("../../../images/header-search.png")}
            className="header-search"
          />
        </Button>
      </Form.Group>
    );
  }

  var handleLogout = async () => {
    await props.logout();
    props.navigate("/");
    await window.location.reload();
  };

  var dialogTimer = "";
  if (showIdleTimerDialog) {
      dialogTimer =
          <div>
              <IdleTimer
                  ref={ idleTimerInDialog => idleTimerInDialog }
                  element={document}
                  onIdle={() => {
                      //this.setState({showIdleTimerDialog: false});
                      handleLogout();
                  }}
                  debounce={250}
                  timeout={1000 * 60 * 2} />
          </div>
  } else {
      dialogTimer = "";
  }

  return (
    <div className="search">
      <DashboardFilterModal
        data={{ showFilter: showFilter }}
        result={(result) => {
          setShowFilter(result.showFilter);
        }}
      />
      <Form className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <IdleTimer
          ref={idleTimer}
          element={document}
          onIdle={onIdle}
          debounce={250}
          timeout={1000 * 300}
          stopOnIdle={true}
       />
       <Modal show={showIdleTimerDialog} size="xl" centered>
           <Modal.Body className="p-1">
             { dialogTimer }
             <div class="form-main-title my-2">
               <label>Your Session Expired!</label>
             </div>
             <Form.Group as={Col} controlId="">
                <Form.Label className="table-form-label mb-0">
                  Any confirmed transactions are saved, but you'll need to restart any searches or unfinished transactions.
                </Form.Label>
                <Form.Label className="table-form-label mb-0">
                  Do you want to continue?
                </Form.Label>
             </Form.Group>
           </Modal.Body>
           <Modal.Footer>
             <Button
               className="btn grid-btn"
               variant="secondary"
               onClick={() => {
                 idleTimer.current.reset();
                 setShowIdleTimerDialog(false);
               }}
             >
               Yes, Continue
             </Button>
             <Button
                className="btn grid-btn"
                variant="secondary"
                onClick={() => {
                  handleLogout();
                }}
              >
                No, Logout
              </Button>
           </Modal.Footer>
         </Modal>

        <div className="d-flex">
          {mrnSearch}
          {accountNumberSearch}
          {lastNameSearch}
          {firstNameSearch}
          {dobSearch}
          {acctSearchBtn}
          {portalFilterBtn}
          {clientOrgSearch}
          {payerOrgSearch}
          {payerSearch}
          {payerSearchBtn}
          {empOrgSearch}
          {empClientSearch}
          {empFacilitySearch}
          {corrTitleSearch}
          {corrClientSearch}
          {corrPatientSearch}
          {corrAuthIdSearch}
          {corrPayerSearch}
          {corrSearchBtn}
        </div>
      </Form>
      <div className="mobile-search d-block d-sm-block d-md-none d-lg-none d-xl-none">
        <div onClick={handleShow}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-search"
            viewBox="0 0 16 16"
          >
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          <div className="mobile-search-text">
            <p>SEARCH</p>
          </div>
        </div>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Search</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form.Group className="search-form-group">
              <Form.Label className="mb-0 text-muted form-label">
                MRN:
              </Form.Label>
              <Form.Control
                name="mrn"
                type="text"
                placeholder="Enter MRN"
                onChange={(e) => setMrn(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="search-form-group">
              <Form.Label className="mb-0 text-muted form-label">
                Last Name:{" "}
              </Form.Label>
              <Form.Control
                name="lastname"
                type="text"
                placeholder="Enter Last Name"
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="search-form-group">
              <Form.Label className="mb-0 text-muted form-label">
                First Name:
              </Form.Label>
              <Form.Control
                name="firstname"
                type="text"
                placeholder="Enter First Name"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="search-form-group">
              <Form.Label className="mb-0 text-muted form-label search-dob">
                Date of Birth:
              </Form.Label>
              <DatePicker
                className="search-date-picker"
                showIcon
                placeholderText="MM/DD/YYYY"
                calendarIconClassname="search-date-picker-icon"
                customInput={
                  <MaskedTextInput type="text" mask={MaskDateInput} />
                }
                selected={searchDob}
                onChange={(date) => setDob(date)}
              />
            </Form.Group>
            <Form.Group className="search-form-group">
              <Button
                className="btn btn-primary"
                variant="primary"
                type="button"
                onClick={(e) => {
                  search();
                  if (
                    searchMrn ||
                    searchLastName ||
                    searchFirstName ||
                    searchDob
                  ) {
                    handleClose();
                  }
                }}
              >
                SEARCH
              </Button>
              <Button
                className="btn btn-primary mx-2"
                variant="primary"
                type="button"
                onClick={(e) => {
                  handleClose();
                }}
              >
                CANCEL
              </Button>
            </Form.Group>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    guarantorPatientsSearch: bindActionCreators(
      guarantorPatientsSearch,
      dispatch
    ),
    guarantorSearch: bindActionCreators(guarantorSearch, dispatch),
    patientsSearch: bindActionCreators(patientsSearch, dispatch),
    selectedMenu: bindActionCreators(selectedMenu, dispatch),
    selectedMenuSearch: bindActionCreators(selectedMenuSearch, dispatch),
    getClientsByOrgId: bindActionCreators(getClientsByOrgId, dispatch),
    patientAddressClear: bindActionCreators(patientAddressClear, dispatch),
    patientPhoneEmailClear: bindActionCreators(
      patientPhoneEmailClear,
      dispatch
    ),
    facilitiesClear: bindActionCreators(facilitiesClear, dispatch),
    providerClear: bindActionCreators(providerClear, dispatch),
    clientEmployeesClear: bindActionCreators(providerClear, dispatch),
    addUpdateClientSelected: bindActionCreators(
      addUpdateClientSelected,
      dispatch
    ),
    getEmployeeByOrgId: bindActionCreators(getEmployeeByOrgId, dispatch),
    getEmployeeByClientId: bindActionCreators(getEmployeeByClientId, dispatch),
    getEmployeeByFacilityId: bindActionCreators(
      getEmployeeByFacilityId,
      dispatch
    ),
    getFacilities: bindActionCreators(getFacilities, dispatch),
    getCorrespondenceListBySearch: bindActionCreators(
      correspondenceSearch,
      dispatch
    ),
    logout: bindActionCreators(logout, dispatch),
    getPayersByOrgId: bindActionCreators(getPayersByOrgId, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    menu: state.menu.data,
    menuSearch: state.menu.dataSearch,
    organizationList: state.getAllOrganization.data,
    clientList: state.getClientsByOrgId.data,
    facilityList: state.getFacilities.data,
    payers: state.getPayersAll.data,
    correspondenceList: state.correspondenceSearch.data,
    user: state.currentUser.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(Search));
