import React, { useState, Component } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {useNavigate, withRouter} from "react-router-dom";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import MaskedInput from 'react-maskedinput';

import getUser from "../../../redux/actions/api/authentication/GetUserApiActions";
import {WithRouter} from "../../common/WithRouter";

import {
    Table,
    Tab,
    Tabs,
    ButtonGroup,
    Dropdown,
    DropdownButton,
    Accordion,
    useAccordionButton,
    Modal
} from 'react-bootstrap';
import getOrganization from "../../../redux/actions/api/organization/GetOrganizationApiActions";
import {getEncounterDTO} from "../../../redux/actions/api/encounter/GetEncounterDTOApiActions";
import getAllOrganization from "../../../redux/actions/api/organization/GetAllOrganizationApiActions";
import getPayersFindAll from "../../../redux/actions/api/payer/GetPayersApiActions";
import {setAuthDto} from "../../../redux/actions/api/authorization/CreateAuthDTOApiActions";
import getClientsByOrgId from "../../../redux/actions/api/client/GetClientsByOrgIdApiActions";
import getFacilities, {facilitiesClear} from "../../../redux/actions/api/facility/GetFacilitiesApiActions";
import {getSystemsCode} from "../../../redux/actions/api/systemsCode/GetSystemsCodeApiActions";

class DashboardFilterModal extends Component {

    state = { result: {showFilter: false}};

    constructor(props) {
        super(props);
        this.state = {
            key: 1,
            activePage: 1,
            recordsPerPage: 10,
            activeRecords: [],
            showFilter: false,
            sort:'DESC',
            sortHeader:'',
            all: true,
            lastWeek: false,
            last2Weeks: false,
            lastMonth: false,
            last3Months: false,
            payer: "",
            org: "",
            client: "",
            facility: "",
            preAuthStatuses: [],
            checkAll: false,
            newStatus: false,
            affirmed: false,
            nonaffirmed: false,
            returnToClient: false,
            appeal: false,
            lastName: "",
            firstName: "",
            dob: "",
            accountNumber: "",
            mrn: "",
            orderNumber: "",
            dispositionCode: ""
        };

    }

    async componentDidMount() {
        if(!this.props.user) {
            let userId = sessionStorage.getItem("userId");
            await this.props.getUser(userId);
        }
        await this.props.getAllOrganization();
        await this.props.getPayersFindAll();
        await this.props.getSystemsCode();

        if(this.props.user.userRole != 'AUTHOFI ADMIN')
        {
            await this.props.getClients(this.props.user.orgId);
            this.setState({org:this.props.user.orgId});
        }
    }

    handleShowCloseFilter = (val) => {
        let result = { showFilter: val };
        this.props.result(result);
    };

    authorizationDTO = () => {
        let {encounterDTO} = this.props;
        let auth = [];

        encounterDTO.forEach((dto) => {
            auth.push(dto.authorization);
        });

        return auth;
    }

    calculateNew = () => {
        let auth = this.authorizationDTO();

        let newAuth = auth.filter((auth) => auth.preAuthStatus == "NEW");
        return newAuth.length
    }

    calculateAffirmed = () => {
        let auth = this.authorizationDTO();

        let newAuth = auth.filter((auth) => auth.preAuthStatus == "AFFIRMED DECISION");
        return newAuth.length
    }

    calculateNonAffirmed = () => {
        let auth = this.authorizationDTO();

        let newAuth = auth.filter((auth) => auth.preAuthStatus == "NON-AFFIRMED DECISION");
        return newAuth.length
    }

    calculateReturnToClient = () => {
        let auth = this.authorizationDTO();

        let newAuth = auth.filter((auth) => auth.preAuthStatus == "RETURNED TO CLIENT");
        return newAuth.length
    }

    calculateAppeal = () => {
        let auth = this.authorizationDTO();

        let newAuth = auth.filter((auth) => auth.preAuthStatus == "APPEAL");
        return newAuth.length
    }

    calculateTotal = () => {
        return this.props.authorization.length
    };

    selectPayer = (e) => {
        this.setState({payer: e.target.value});
        console.log(e.target.value)
    }

    selectOrg = (e) => {
        this.setState({org: e.target.value});
        this.props.getClients(e.target.value);
    }

    selectClient = (e) => {
        this.setState({client: e.target.value});
        this.props.facilitiesClear();
        this.props.getFacilities(e.target.value);
    }

    checkAll = (e) => {
        let val = e.target.checked;
        this.setState({checkAll: val, newStatus: val, affirmed: val, nonaffirmed: val, returnToClient: val, appeal: val})
    }

    applyFilter = async (e) => {
        const {all, lastWeek, last2Weeks, lastMonth, last3Months, payer, org, client, facility, preAuthStatuses, checkAll,
            newStatus, affirmed, nonaffirmed, returnToClient, appeal, lastName, firstName, dob, accountNumber, mrn, orderNumber, dispositionCode } = this.state;
        let filter = {};
        if(all) {
            filter.dateString = "";
        }
        if(lastWeek) {
            var currentDate = new Date();
            var beforeDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
            filter.dateString = moment(beforeDate).format("YYYY-MM-DD");
        }
        if(last2Weeks) {
            var currentDate = new Date();
            var beforeDate = new Date(currentDate.setDate(currentDate.getDate() - 14));
            filter.dateString = moment(beforeDate).format("YYYY-MM-DD");
        }
        if(lastMonth) {
            var currentDate = new Date();
            var beforeDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
            filter.dateString = moment(beforeDate).format("YYYY-MM-DD");
        }
        if(last3Months) {
            var currentDate = new Date();
            var beforeDate = new Date(currentDate.setDate(currentDate.getDate() - 90));
            filter.dateString = moment(beforeDate).format("YYYY-MM-DD");
        }
        if(payer != "") {
            filter.payerId = payer;
        } else {
            filter.payerId = null;
        }
        if(org != "") {
            filter.orgId = org;
        } else {
            filter.orgId = null;
        }
        if(client != "") {
            filter.clientId = client;
        } else {
            filter.clientId = null;
        }
        if(facility != "") {
            filter.facilityId = facility;
        } else {
            filter.facilityId = null;
        }
        let preAuthStatus = [];
        if(newStatus) {
            preAuthStatus.push("NEW");
        }
        if(affirmed) {
            preAuthStatus.push("AFFIRMED DECISION");
        }
        if(nonaffirmed) {
            preAuthStatus.push("NON-AFFIRMED DECISION");
        }
        if(returnToClient) {
            preAuthStatus.push("RETURNED TO CLIENT");
        }
        if(appeal) {
            preAuthStatus.push("APPEAL");
        }
        console.log(preAuthStatus);
        if(preAuthStatus.length > 0) {
            let statuses = "'" + preAuthStatus.toString().replace(/,/g, "', '") + "'";
            filter.preAuthStatus = statuses;
            console.log(statuses)
        } else {
            filter.preAuthStatus = "";
        }
        if(lastName) {
            filter.lastName = lastName;
        }
        if(firstName) {
            filter.firstName = firstName;
        }
        if(dob) {
            filter.dob = moment(dob).format("YYYY-MM-DD");
        } else {
            filter.dob = undefined;
        }
        if(accountNumber) {
            filter.accountNumber = accountNumber;
        }
        if(mrn) {
            filter.mrn = mrn;
        }
        if(orderNumber) {
            filter.orderNumber = orderNumber;
        }
        if(dispositionCode) {
            filter.dispositionCode = dispositionCode;
        }
        console.log(filter)
        await this.props.getEncounterDTO(filter);
        let result = { showFilter: false };
        this.props.result(result);
        //this.props.navigate('/portal');
    }

    clearFilter = async () => {
        await this.setState({
            all: true, lastWeek: false, last2Weeks: false, lastMonth: false, last3Months: false,
            payer: "", org: "", client: "", facility: "", checkAll: false, newStatus: false, affirmed: false,
            nonaffirmed: false, returnToClient: false, appeal: false, lastName: "", firstName: "", dob: "", accountNumber: "", mrn: "", orderNumber: "",
            dispositionCode: ""
        });
        await this.props.getEncounterDTO({});
        let result = { showFilter: false };
        this.props.result(result);
    }

    render() {
        const {sortHeader, sort, all, lastWeek, last2Weeks, lastMonth, last3Months, payer, org, client, facility, preAuthStatuses, checkAll,
                    newStatus, affirmed, nonaffirmed, returnToClient, appeal, lastName, firstName, dob, accountNumber, mrn, orderNumber, dispositionCode } = this.state;
        const {user} = this.props;
        var orgList;
        if(user.userRole == 'AUTHOFI ADMIN')
        {
            orgList = <div className="mt-1">
                              <Form.Group as={Col} controlId="">
                                  <Form.Label className="table-form-label mb-0">Organization</Form.Label>
                                  <Form.Select className="grid-form-control" value={org} onChange={this.selectOrg.bind(this)} >
                                      <option selected value=""> -- Select Organization -- </option>
                                      {this.props.organizationList.map((org, key) => {
                                          return <option key={key} value={org.id}>{org.name}</option>
                                      })}
                                  </Form.Select>
                              </Form.Group>
                          </div>;
        }
        return(
        <Modal show={this.props.data.showFilter} size="lg" centered>
            <Modal.Body class="p-4">
                <div className="form-title-label text-center">
                    <label className="border-bottom">FILTER</label>
                </div>
                <Form>
                    <Row className="mb-1">
                        <div>
                            <Row>
                                <div className="col-md-4">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Last Name</Form.Label>
                                        <Form.Control
                                            maxLength={40}
                                            required
                                            className="grid-form-control"
                                            placeholder=""
                                            value={lastName}
                                            onChange={(e) => this.setState({lastName: e.target.value})}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">First Name</Form.Label>
                                        <Form.Control
                                            maxLength={40}
                                            required
                                            className="grid-form-control"
                                            placeholder=""
                                            value={firstName}
                                            onChange={(e) => this.setState({firstName: e.target.value})}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Group className="search-form-group">
                                            <Form.Label className="mb-0 table-form-label w-100">Date of Birth</Form.Label>
                                            <DatePicker
                                                 className="search-date-picker filter-date-picker mt-0 mb-1"
                                                 showIcon
                                                 customInput={
                                                   <MaskedInput mask="11/11/1111" placeholder="MM/DD/YYYY" />
                                                 }
                                                 selected={dob}
                                                 onChange={(date) => this.setState({dob:date})}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </div>
                            </Row>
                        </div>
                        <div>
                            <Row>
                                <div className="col-md-4">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Account Number</Form.Label>
                                        <Form.Control
                                            maxLength={40}
                                            required
                                            className="grid-form-control"
                                            placeholder=""
                                            value={accountNumber}
                                            onChange={(e) => this.setState({accountNumber: e.target.value})}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">MRN</Form.Label>
                                        <Form.Control
                                            maxLength={40}
                                            required
                                            className="grid-form-control"
                                            placeholder=""
                                            value={mrn}
                                            onChange={(e) => this.setState({mrn: e.target.value})}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Order Number</Form.Label>
                                        <Form.Control
                                            maxLength={40}
                                            required
                                            className="grid-form-control"
                                            placeholder=""
                                            value={orderNumber}
                                            onChange={(e) => this.setState({orderNumber: e.target.value})}
                                        />
                                    </Form.Group>
                                </div>
                            </Row>
                        </div>
                        <div className="d-flex">
                            <Form.Group as={Col} controlId="">
                                <Row>
                                    <div className="col-1">
                                        <Form.Label className="table-form-label mb-0">Date:</Form.Label>
                                    </div>
                                    <div className="col-1">
                                        <Form.Check type="radio" name="radiogroup" className="grid-form-control mt-1" label="All" checked={all} onChange={(e) => {this.setState({all:e.target.checked})}}/>
                                    </div>
                                    <div className="col-2">
                                        <Form.Check type="radio" name="radiogroup" className="grid-form-control mt-1" label="This Week" checked={lastWeek} onChange={(e) => {this.setState({lastWeek:e.target.checked})}}/>
                                    </div>
                                    <div className="col-3">
                                        <Form.Check type="radio" name="radiogroup" className="grid-form-control mt-1" label="Last Two Weeks" checked={last2Weeks} onChange={(e) => {this.setState({last2Weeks:e.target.checked})}}/>
                                    </div>
                                    <div className="col-2">
                                        <Form.Check type="radio" name="radiogroup" className="grid-form-control mt-1" label="Last Month" checked={lastMonth} onChange={(e) => {this.setState({lastMonth:e.target.checked})}}/>
                                    </div>
                                    <div className="col-3">
                                        <Form.Check type="radio" name="radiogroup" className="grid-form-control mt-1" label="Last Three Months" checked={last3Months} onChange={(e) => {this.setState({last3Months:e.target.checked})}}/>
                                    </div>
                                </Row>
                            </Form.Group>
                        </div>
                        <div className="mt-1">
                            <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">Payer</Form.Label>
                                <Form.Select className="grid-form-control" value={payer} onChange={this.selectPayer.bind(this)}>
                                    <option selected value=""> -- Select Payer -- </option>
                                    {this.props.payers.map((payer, key) => {
                                        return <option key={key} value={payer.id}>{payer.name}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        {orgList}
                        <div className="mt-1">
                            <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">Client</Form.Label>
                                <Form.Select className="grid-form-control" value={client} onChange={this.selectClient.bind(this)}>
                                    <option selected value=""> -- Select Client -- </option>
                                    {this.props.clients.map((client, key) => {
                                        return <option key={key} value={client.id}>{client.name}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="mt-1 mb-1">
                            <Form.Group as={Col} controlId="">
                                <Form.Label className="table-form-label mb-0">Facility</Form.Label>
                                <Form.Select className="grid-form-control" value={facility} onChange={(e)=> this.setState({facility: e.target.value})}>
                                    <option selected value=""> -- Select Facility -- </option>
                                    {this.props.facilityList.map((facility, key) => {
                                        return <option key={key} value={facility.id}>{facility.name}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div class="d-flex">
                            <Form.Group as={Col} controlId="">
                                <Row>
                                    <div className="col-1">
                                        <Form.Label className="table-form-label mt-1">Status:</Form.Label>
                                    </div>
                                    <div className="col-3">
                                        <Form.Check className="grid-form-control mt-1" label="Check All" checked={checkAll} onChange={(e) => {this.checkAll(e)}}/>
                                    </div>
                                    <div className="col-3">
                                        <Form.Check className="grid-form-control mt-1" label="New" checked={newStatus} onChange={(e) => this.setState({newStatus: e.target.checked})}/>
                                    </div>
                                    <div className="col-3">
                                        <Form.Check className="grid-form-control mt-1" label="Affirmed Decision" checked={affirmed} onChange={(e) => this.setState({affirmed: e.target.checked})}/>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-1">
                                    </div>
                                    <div className="col-3">
                                        <Form.Check className="grid-form-control mt-1" label="Returned to Client" checked={returnToClient} onChange={(e) => this.setState({returnToClient: e.target.checked})}/>
                                    </div>
                                    <div className="col-3">
                                        <Form.Check className="grid-form-control mt-1" label="Appeal" checked={appeal} onChange={(e) => this.setState({appeal: e.target.checked})}/>
                                    </div>
                                    <div className="col-3">
                                        <Form.Check className="grid-form-control mt-1" label="Non-affirmed Decision"checked={nonaffirmed} onChange={(e) => this.setState({nonaffirmed: e.target.checked})}/>
                                    </div>
                                </Row>
                                <Row>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label className="table-form-label mb-0">Disposition Code</Form.Label>
                                        <Form.Select className="grid-form-control" value={dispositionCode} onChange={(e)=> this.setState({dispositionCode: e.target.value})}>
                                            <option selected value=""> -- Select Disposition Code -- </option>
                                            {this.props.dispositionCodes.map((code, key) => {
                                                return <option key={key} value={code.code}>{code.value}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                <Row className="mt-4">
                                    <div className="col-12">
                                        <Button className="filter-apply-btn2 btn-primary m-1 float-end" type="button" onClick={(e) => this.applyFilter(e)}>
                                            Apply Filter
                                        </Button>
                                        <Button className="filter-clear-btn2 btn-primary m-1 float-end" type="button" onClick={(e) => this.clearFilter(e)}>
                                            Clear Filter
                                        </Button>
                                        <Button className="filter-cancel-btn btn-primary m-1 float-end" type="button"
                                                onClick={() => { this.handleShowCloseFilter(false); }}>
                                            Cancel
                                        </Button>
                                    </div>
                                </Row>
                            </Form.Group>
                        </div>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getEncounterDTO: bindActionCreators(getEncounterDTO, dispatch),
        getAllOrganization: bindActionCreators(getAllOrganization, dispatch),
        getPayersFindAll: bindActionCreators(getPayersFindAll, dispatch),
        getClients: bindActionCreators(getClientsByOrgId, dispatch),
        getFacilities: bindActionCreators(getFacilities, dispatch),
        facilitiesClear: bindActionCreators(facilitiesClear, dispatch),
        getSystemsCode: bindActionCreators(getSystemsCode, dispatch),
        getUser: bindActionCreators(getUser, dispatch),
    }
}

const mapStateToProps = state => {
    return {
        encounterDTO: state.getEncounterDTO.data,
        organizationList: state.getAllOrganization.data,
        payers: state.getPayersAll.data,
        clients: state.getClientsByOrgId.data,
        facilityList: state.getFacilities.data,
        serviceTypes: state.getSystemsCode.serviceTypes,
        dispositionCodes: state.getSystemsCode.dispositionCodes,
        user: state.currentUser.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithRouter(DashboardFilterModal))
