import { CALL_API } from '../../../middleware/api'
import * as GetPayersByOrgIdApiActionConstants from "./GetPayersByOrgIdApiActionConstants"
import { getPayersByOrgIdUriPath } from '../EndpointResolver'

export default function getPayersByOrgId(orgId) {
    return {
        [CALL_API]: {
            endpoint: getPayersByOrgIdUriPath(orgId),
            config: { method: 'get' },
            types: [
                GetPayersByOrgIdApiActionConstants.GET_PAYERSBYORGID_REQUEST,
                GetPayersByOrgIdApiActionConstants.GET_PAYERSBYORGID_SUCCESS,
                GetPayersByOrgIdApiActionConstants.GET_PAYERSBYORGID_FAILED,
            ],
        },
    }
}