import { CALL_API } from "../../../middleware/api";
import * as PatientsSearchApiActionConstants from "./PatientsSearchApiActionConstants";
import { getGuarantorPatientsSearchUriPath } from "../EndpointResolver";

export function patientsSearch(
  orgId,
  mrn,
  accountNumber,
  lastName,
  firstName,
  dob,
  type
) {
  return {
    [CALL_API]: {
      endpoint: getGuarantorPatientsSearchUriPath(
        orgId,
        mrn,
        accountNumber,
        lastName,
        firstName,
        dob,
        type
      ),
      config: { method: "get" },
      types: [
        PatientsSearchApiActionConstants.PATIENTS_SEARCH_REQUEST,
        PatientsSearchApiActionConstants.PATIENTS_SEARCH_SUCCESS,
        PatientsSearchApiActionConstants.PATIENTS_SEARCH_FAILED,
      ],
    },
  };
}

export function updatePatientsSearchResult(val) {
  return function (dispatch) {
    dispatch({
      type: PatientsSearchApiActionConstants.PATIENTS_SEARCH_UPDATE,
      val: val,
    });
  };
}
