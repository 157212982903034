import * as GetPayersByOrgIdApiActionConstants from '../../redux/actions/api/payer/GetPayersByOrgIdApiActionConstants'

const initialState = {
    data: []
};

export default function getPayersByOrgIdReducer(state = initialState, action) {
    switch (action.type) {
        case GetPayersByOrgIdApiActionConstants.GET_PAYERSBYORGID_REQUEST:
            return {
                ...state,
            };
        case GetPayersByOrgIdApiActionConstants.GET_PAYERSBYORGID_SUCCESS: {
            return {
                ...state,
                data: action.data
            };
            break;
        }
        case GetPayersByOrgIdApiActionConstants.GET_PAYERSBYORGID_FAILED:
            return {
                ...state,
                data: []
            };
        default:
            return state
    }
}
