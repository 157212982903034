import { CALL_API } from "../../../middleware/api";
import * as GuarantorSearchApiActionConstants from "./GuarantorSearchApiActionConstants";
import { getGuarantorPatientsSearchUriPath } from "../EndpointResolver";

export function guarantorSearch(orgId, mrn, lastName, firstName, dob, type) {
  return {
    [CALL_API]: {
      endpoint: getGuarantorPatientsSearchUriPath(
        orgId,
        mrn,
        "",
        lastName,
        firstName,
        dob,
        type
      ),
      config: { method: "get" },
      types: [
        GuarantorSearchApiActionConstants.GUARANTOR_SEARCH_REQUEST,
        GuarantorSearchApiActionConstants.GUARANTOR_SEARCH_SUCCESS,
        GuarantorSearchApiActionConstants.GUARANTOR_SEARCH_FAILED,
      ],
    },
  };
}

export function updateGuarandorsSearchResult(val) {
  return function (dispatch) {
    dispatch({
      type: GuarantorSearchApiActionConstants.GUARANTOR_SEARCH_UPDATE,
      val: val,
    });
  };
}
